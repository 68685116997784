

.ag-theme-ri-scanner,
.ag-theme-ri-scanner .ag-details-grid {
    --ag-font-family: "Inter", sans-serif;
    --ag-header-background-color: #222628;
    --ag-background-color: #181D20;
    --ag-control-panel-background-color: #222628;
    --ag-row-height: 32px;
    --ag-header-height: 32px;
    --ag-font-size: 14px;
    --ag-cell-horizontal-padding: 4px; 
    --ag-grid-size: 2px;
    --ag-line-height: 20px;
}

.ag-theme-ri-scanner .ag-header-cell-label,
.ag-theme-ri-scanner .ag-details-grid .ag-header-cell-label
{
    font-size: 12px;
    font-weight: 650;
}

.ag-theme-ri-scanner-scanner .ag-details-row .ag-header,
.ag-theme-ri-scanner .ag-details-row .ag-header-row,
.ag-theme-ri-scanner .ag-details-row .ag-header-cell
{
    height: 32px !important;
    min-height: 32px !important;
} 
.ag-theme-ri-scanner .ag-cell-value {
    word-break: normal;
}

.ag-theme-ri-scanner .ag-cell-wrapper {
    overflow: hidden;
}






