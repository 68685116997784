
.ag-theme-ri-notespage {
    --ag-grid-size: 2px;
    --ag-font-family: "Inter var", sans-serif;
    --ag-header-background-color: #222628;
    --ag-background-color: #181D20;
    --ag-control-panel-background-color: #222628;
    --ag-font-size: 12.5px;
}

.ag-theme-ri-notespage .ag-cell-value {
    word-break: normal;
}


.ag-theme-ri-notespage .notes-tables-yellow-lap {
    background-color: #e8da0ec0;
    background-image: unset;
}

.ag-theme-ri-notespage .notes-tables-green-lap {
    background-color: #00ff0085;
    background-image: unset;
}