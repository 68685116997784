


/* redone styles */
.ag-theme-ri-leaderboard {
    --ag-grid-size: 3px;
    --ag-font-family: "Inter var", sans-serif;
    --ag-header-background-color: #222628;
    --ag-background-color: #181D20;
    /* --ag-odd-row-background-color: #222628; */
    --ag-control-panel-background-color: #222628;
    /* --ag-row-height: 24px; */
    /* --ag-font-size: 13px; */
    /* --ag-list-item-height: 20px;
    --ag-font-size: 10pt;
    --ag-row-height: 22px;
    --ag-header-height: 28px;
    --ag-column-select-indent-size: 0px;
    --ag-cell-horizontal-padding: 4px; */
}


.ag-theme-ri-leaderboard .ag-header-cell-label {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    font-weight: 650;
    overflow: clip;
}

.ag-theme-ri-leaderboard .ag-column-select-list {
    height: 510px;
    max-height: 510px; 
}

.ag-theme-ri-leaderboard .ag-column-drop-cell {
    height: 18px;
}
 

/* In playoffs */
.ag-theme-ri-leaderboard .ag-cell-playoffs {
    border-color: yellow !important
}

/* Pos Delta Cell Colors */
.ag-theme-ri-leaderboard .ag-cell-delta-minus-3plus {
    background-color: #ff0000 !important;
    z-index: 10;
    background-image: unset;

}

.ag-theme-ri-leaderboard .ag-cell-delta-minus-2 {
    background-color: #ff00008a !important;
    z-index: 10;
    background-image: unset;

}

.ag-theme-ri-leaderboard .ag-cell-delta-minus-1 {
    background-color: #ff000034 !important;
    z-index: 10;
    background-image: unset;
}

.ag-theme-ri-leaderboard .ag-cell-delta-plus-1 {
    background-color: #00ff0034 !important;
    z-index: 10;
    background-image: unset;
}

.ag-theme-ri-leaderboard .ag-cell-delta-plus-2 {
    background-color: #00ff008a !important;
    z-index: 10;
    background-image: unset;
}
.ag-theme-ri-leaderboard .ag-cell-delta-plus-3plus {
    background-color: #2ad72a !important;
    background-image: unset;
}


.ag-theme-ri-leaderboard .ag-row-on-dvp {
    background-color: #b93018a3;
    background-image: unset;

}
.ag-theme-ri-leaderboard .ag-row-in-pits {
    background-color: #c8bc0385;
    background-image: unset;
}

.ag-theme-ri-leaderboard .ag-row-off-track {
    background-color: #000000;
    color: #dfdfdf;

}

.ag-theme-ri-leaderboard .ag-cell-delta-next-close {
    border-color: #ff3700;

}


/* MUI child Table CSS. but why here? */
.ag-theme-ri-leaderboard .MuiTableCell-root {
    font-size: 10pt !important;
    padding: 1px 4px 1px 4px !important;
}




/* ICONS */
.ag-theme-ri-leaderboard .flag-icon {
    background: transparent
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZD0iTTEwIDQyVjhoMTcuMTVsLjk1IDQuM0g0MHYxOC41SDI3LjJsLS45NS00LjI1SDEzVjQyWiIvPjwvc3ZnPg==)
    no-repeat center;
  background-size: 16px 16px;
  height: 16px;
  opacity: 0.87;
  width: 16px;
  max-width: 16px;
  display: inline-block;
  filter: invert(1)
}

.ag-theme-ri-leaderboard .leader-icon {
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDAiIHdpZHRoPSI0MCI+PHBhdGggZD0iTTE5LjgzMyAyOC40MTdoMy4yOTJWMTEuNTgzaC02LjcwOHYzLjI5MmgzLjQxNlptLTExLjYyNSA3Ljc1cS0xLjgzMyAwLTMuMTA0LTEuMjcxLTEuMjcxLTEuMjcxLTEuMjcxLTMuMTA0VjguMjA4cTAtMS44MzMgMS4yNzEtMy4xMjUgMS4yNzEtMS4yOTEgMy4xMDQtMS4yOTFoMjMuNTg0cTEuODMzIDAgMy4xMjUgMS4yOTEgMS4yOTEgMS4yOTIgMS4yOTEgMy4xMjV2MjMuNTg0cTAgMS44MzMtMS4yOTEgMy4xMDQtMS4yOTIgMS4yNzEtMy4xMjUgMS4yNzFaIi8+PC9zdmc+) no-repeat center;
      background-size: 16px 16px;
  height: 16px;
  opacity: 0.87;
  width: 16px;
  max-width: 16px;
  display: inline-block;
  filter: invert(1)
}

.ag-theme-ri-leaderboard .options-icon {
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZD0ibTE5LjQgNDQtMS02LjNxLS45NS0uMzUtMi0uOTV0LTEuODUtMS4yNWwtNS45IDIuN0w0IDMwbDUuNC0zLjk1cS0uMS0uNDUtLjEyNS0xLjAyNVE5LjI1IDI0LjQ1IDkuMjUgMjRxMC0uNDUuMDI1LTEuMDI1VDkuNCAyMS45NUw0IDE4bDQuNjUtOC4yIDUuOSAyLjdxLjgtLjY1IDEuODUtMS4yNXQyLS45bDEtNi4zNWg5LjJsMSA2LjNxLjk1LjM1IDIuMDI1LjkyNVEzMi43IDExLjggMzMuNDUgMTIuNWw1LjktMi43TDQ0IDE4bC01LjQgMy44NXEuMS41LjEyNSAxLjA3NS4wMjUuNTc1LjAyNSAxLjA3NXQtLjAyNSAxLjA1cS0uMDI1LjU1LS4xMjUgMS4wNUw0NCAzMGwtNC42NSA4LjItNS45LTIuN3EtLjguNjUtMS44MjUgMS4yNzUtMS4wMjUuNjI1LTIuMDI1LjkyNWwtMSA2LjNaTTI0IDMwLjVxMi43IDAgNC42LTEuOSAxLjktMS45IDEuOS00LjYgMC0yLjctMS45LTQuNi0xLjktMS45LTQuNi0xLjktMi43IDAtNC42IDEuOS0xLjkgMS45LTEuOSA0LjYgMCAyLjcgMS45IDQuNiAxLjkgMS45IDQuNiAxLjlaIi8+PC9zdmc+) no-repeat center;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    max-width: 16px;
    display: inline-block;
    filter: invert(1)
}

.ag-theme-ri-leaderboard .chart-icon {
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZD0ibTE0LjggMzEuMyA2LjEtNi4xIDQgNCA3LjYtNy41NXYzLjg1aDN2LTloLTl2M2gzLjg1bC01LjQ1IDUuNDUtNC00LTguMiA4LjI1Wk05IDQycS0xLjIgMC0yLjEtLjlRNiA0MC4yIDYgMzlWOXEwLTEuMi45LTIuMVE3LjggNiA5IDZoMzBxMS4yIDAgMi4xLjkuOS45LjkgMi4xdjMwcTAgMS4yLS45IDIuMS0uOS45LTIuMS45Wm0wLTNoMzBWOUg5djMwWk05IDl2MzBWOVoiLz48L3N2Zz4=) no-repeat center;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    max-width: 16px;
    display: inline-block;
    filter: invert(1)
}

.ag-theme-ri-leaderboard .info-icon {

    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZD0iTTIyLjY1IDM0aDNWMjJoLTNaTTI0IDE4LjNxLjcgMCAxLjE3NS0uNDUuNDc1LS40NS40NzUtMS4xNXQtLjQ3NS0xLjJRMjQuNyAxNSAyNCAxNXEtLjcgMC0xLjE3NS41LS40NzUuNS0uNDc1IDEuMnQuNDc1IDEuMTVxLjQ3NS40NSAxLjE3NS40NVpNMjQgNDRxLTQuMSAwLTcuNzUtMS41NzUtMy42NS0xLjU3NS02LjM3NS00LjMtMi43MjUtMi43MjUtNC4zLTYuMzc1UTQgMjguMSA0IDIzLjk1cTAtNC4xIDEuNTc1LTcuNzUgMS41NzUtMy42NSA0LjMtNi4zNSAyLjcyNS0yLjcgNi4zNzUtNC4yNzVRMTkuOSA0IDI0LjA1IDRxNC4xIDAgNy43NSAxLjU3NSAzLjY1IDEuNTc1IDYuMzUgNC4yNzUgMi43IDIuNyA0LjI3NSA2LjM1UTQ0IDE5Ljg1IDQ0IDI0cTAgNC4xLTEuNTc1IDcuNzUtMS41NzUgMy42NS00LjI3NSA2LjM3NXQtNi4zNSA0LjNRMjguMTUgNDQgMjQgNDRaIi8+PC9zdmc+) no-repeat center;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    max-width: 16px;
    display: inline-block;
    filter: invert(1)
}

.ag-theme-ri-leaderboard .ag-row-level-1 {
    background-color: unset !important;
}