
.ag-theme-ri-penaltytool {
    --ag-grid-size: 3px;
    --ag-font-family: "Inter var", sans-serif;
    --ag-header-background-color: #222628;
    --ag-background-color: #181D20;
    --ag-control-panel-background-color: #222628;
}

.ag-theme-ri-penaltytool .ag-cell-value {
    word-break: normal;
}

