

.ag-theme-ri,
.ag-theme-ri .ag-details-grid {
    --ag-font-family: "Inter var", sans-serif;
    --ag-header-background-color: #222628;
    --ag-background-color: #181D20;
    /* --ag-odd-row-background-color: #222628; */
    --ag-control-panel-background-color: #222628;
    --ag-row-height: 32px;
    --ag-header-height: 32px;
    --ag-font-size: 14px;
    --ag-cell-horizontal-padding: 4px; 
    --ag-grid-size: 4px;
}

.ag-theme-ri .ag-header-cell-label,
.ag-theme-ri .ag-details-grid .ag-header-cell-label
{
    /* font-family: "Roboto Condensed"; */
    font-size: 12px;
    font-weight: 650;
    overflow: clip;
}

.ag-theme-ri .ag-details-row .ag-header,
.ag-theme-ri .ag-details-row .ag-header-row,
.ag-theme-ri .ag-details-row .ag-header-cell
{
    height: 32px !important;
    min-height: 32px !important;
} 


/* playoff row background colors */
.ag-theme-ri .background-playoffs {
  background-color:#11ff001e !important;
}

.ag-theme-ri .background-playoffs-r16 {
  background-color:#11ff0031 !important;

}

.ag-theme-ri .background-playoffs-r12 {
  background-color:#11ff0042 !important;

}

.ag-theme-ri .background-playoffs-r8 {
  background-color:#11ff004b !important;

}

.ag-theme-ri .background-playoffs-r4 {
  background-color:#00ff0063 !important;

}

.ag-theme-ri .ag-cell-value {
    word-break: normal;
}

.ag-theme-ri .ag-cell-wrapper {
    overflow: hidden;
}






