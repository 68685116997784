/* use dark color scheme */
* {
    color-scheme: dark;
}


/* remove calendar icon from inputs with type date */
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}


/* tooltip used in various places */
.ri-custom-tooltip {
  /* material dark background kinda vibe */
  background-color: #1e1e1e;
  color: #fff;
  /* border: 1px solid #fff; */
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  /* drop shadow */
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);

}

/* some non-important ag-grid fixes. DO NOT OVERRIDE AG GRID SIZING VARIABLES!!!! */
/* i would rather have text clip than elipse */
.ag-header-cell-text {
    text-overflow: clip;
}

/* fix sparkline tooltip just not working */
.ag-sparkline-tooltip-wrapper {
  position: absolute !important;
}

/* vertically align text inside of cells */
/* this overrides in-cell spacing to make it aligned vertically */
.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-header-row .ag-header-cell {
  display: flex;
  align-items: center;
}


/* hide ag grid watermark until license is renewed */
.ag-watermark {
  display: none !important;
}
.ag-watermark-text {
  display: none !important;
}


.ag-chart {
    font-family: 'Inter', sans-serif;

}

.ag-chart-tooltip {
    font-family: 'Inter', sans-serif;
}

